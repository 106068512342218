
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '@/scss/app.scss';

.header {
  @include desktop {
    padding: 0 50px;
  }
}

.appbar-title h1 {
  @include tablet {
    font-size: 16px !important;
    span > span {
      font-weight: 500 !important;
    }
  }
}

h1 {
  line-height: 1.2 !important;
}

.ie-blocker--container {
  position: relative !important;
  .ie-blocker--not-compatible-text {
    color: $ads-navy;
  }
  .v-icon {
    color: $ads-navy;
  }
}

.v-btn.settingsBtn.theme--dark.v-size--default {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: $ads-light-blue !important;
  min-width: 40px;
  .v-btn__content .v-icon {
    color: $ads-navy;
  }
}

.v-toolbar__title {
  overflow: visible;
}

.listHeading {
  font-weight: bold;
  color: $ads-dark;
}
.v-btn.trainingMenu .v-btn__content {
  color: $ads-red;
  font-weight: bold;
}

.v-btn:not(.v-btn--round).v-size--default.resumeApplicationBtn {
  padding: 0 8px;
}
