
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .step {
  display: flex;
  align-items: center;
}

::v-deep .step-name {
  white-space: normal;
  line-height: 1.3;
  font-weight: 500;
}

::v-deep .selected .step-name {
  font-weight: bold;
}
