
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.return-text {
  margin-top: 2rem;
  font-size: 1rem; //16px if root font-size is 16px which it appears to be.
  font-weight: 500;
  color: $ads-navy;
  word-break: break-word;
}
.form-container {
  padding-top: 40px;
  border-radius: 4px;
}
