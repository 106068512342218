
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

@import '~vuetify/src/styles/settings/_variables';
a:focus {
  border: 2px solid $ads-navy !important;
  outline-color: $ads-navy !important;
}

a {
  text-decoration: underline;
}
.btn-search {
  width: 100%;
}
.errorRed {
  color: $ads-error-red;
}

.info-card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 -1rem 2rem -1rem;
}

.info-wrapper {
  flex-basis: 33.3333%;
  padding: 1rem;

  @include tablet {
    flex-basis: 50%;
  }

  @include mobile {
    flex-basis: 100%;
  }

  ::v-deep .info-card,
  ::v-deep .info-card__wrapper {
    height: 100%;
  }
}

#what-need-info-card ::v-deep .info-card__icon {
  left: 54%;
  top: 52%;
}

.addressFinder {
  padding: 1.5rem 1.5rem 0 1.5rem;

  &__heading {
    font-size: 1.5rem;
    color: $color-text-body;
  }

  &__description {
    color: $ads-dark;
  }

  &__error-message {
    display: flex;
    align-items: flex-start;
    margin: 0 -8px;

    .error-message__icon,
    .error-message__text,
    .error-message__text ::v-deep a {
      color: $ads-error-red;
    }

    .error-message__text {
      line-height: 1.5;
    }

    .error-message__icon {
      margin-right: 0.5rem;
      font-size: 2rem;
    }
  }
}

.white-card.school-card {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 1.5rem 2.75rem;
  margin: 0 -1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }
}

.school-info {
  padding: 0 1.5rem;

  &__title {
    font-size: 1.5rem;
    color: $color-text-body;
  }
}

.schools {
  width: 100%;
}

.studentAddressInfoAlert {
  ::v-deep .v-alert {
    background-color: $color-ads-primary-teal-2 !important;
    border: none !important;
    box-shadow: none !important;

    &:focus {
      border: none;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
        0 0 0 0 rgba(0, 0, 0, 0.12) !important;
    }
    p {
      margin-bottom: 0 !important;
    }
  }
}
.blockDateAlert {
  ::v-deep .v-alert {
    background-color: $color-orange-light !important;

    .v-alert__wrapper {
      padding: 5px;
    }

    .alert--textsimple {
      color: $ads-grey-01;
    }
  }
}
.invalidSchoolAlert {
  ::v-deep .v-alert {
    background-color: $color-orange-light !important;
    border: none !important;
    box-shadow: none !important;
    border-radius: 4px;

    &:focus {
      border: none;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 0 0 0 rgba(0, 0, 0, 0.14),
        0 0 0 0 rgba(0, 0, 0, 0.12) !important;
    }
    p {
      margin-bottom: 0 !important;
    }
    .v-icon {
      color: $ads-warning-orange;
    }
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 0;
  }
}

::v-deep .AppTypeAhead {
  width: 100%;
}

::v-deep .AppTypeAhead_input {
  width: 100%;
  margin-right: 0;
  background: url('~@/assets/icon-magnify.svg') no-repeat scroll 13px 18px;
  padding: 0 2.5em;
}

::v-deep .hasAlert .AppTypeAhead_input {
  background: url('~@/assets/icon-magnify-red.svg') no-repeat scroll 13px 18px;
}

//Search button + address input animation for mobile
@media (max-width: #{map-get($grid-breakpoints, 'lg') - 1}) {
  .search-button-enter-active,
  .search-button-leave-active {
    transition: all 0.2s;
    overflow: hidden;
  }
  .search-button-enter,
  .search-button-leave-to {
    &,
    & * {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      max-height: 0 !important;
    }
  }

  .search-button-enter-active,
  .search-button-enter-to {
    &,
    & * {
      max-height: 10rem;
    }
  }
}

//Search button + address input animation for desktop
@media (min-width: #{map-get($grid-breakpoints, 'lg')}) {
  .oes-address-finder__container {
    position: relative;
    transition: all 0.2s;
    transition-delay: 0s;

    &--full {
      transition-delay: 0.2s;
    }
  }

  .search-button-enter-active,
  .search-button-leave-active {
    transition: all 0.2s;
  }
  .search-button-enter,
  .search-button-leave-to {
    opacity: 0;
  }

  .search-button-enter-active {
    position: absolute;
    right: 0.5rem;
    transition: opacity 0.2s;
    transition-delay: 0.2s;
    padding-left: 14px;
    padding-right: 16px;
  }
  .residential-proof-btn {
    margin-left: -2px;
  }
}
