
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#ModalResidentialAddress {
  font-weight: 500;

  .points {
    margin-top: 0.75rem;
    font-weight: bold;
  }

  h1 {
    margin-bottom: 1.25rem;
    color: $ads-navy;
    font-weight: bold;
    font-size: 1.25rem;
  }

  table {
    counter-reset: row;
    margin-top: 1.25rem;
    font-size: 1rem;
    width: 100%;
    border-collapse: collapse;

    p {
      margin: 0;
    }
  }

  th {
    background: #1d428a;
    border: 1px solid #1d428a;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    text-align: left;
  }

  td {
    border: 1px solid #a0a5ae;
    padding: 1rem;
    counter-increment: row;
  }

  ol {
    counter-reset: item;
    padding-left: 0;
  }
  li {
    display: block;
  }
  li:before {
    content: counter(row) '.' counter(item) ' ';
    counter-increment: item;
  }
}
