
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

h2 {
  font-size: 24px;
  color: $ads-navy !important;
}
.error-container {
  background-color: $ads-white;
  border-radius: 4px;
  text-align: center;

  .icon-container {
    background-color: $ads-light-blue;
    border-radius: 50%;
    width: 161px;
    height: 161px;
    text-align: center;
    vertical-align: middle;
    margin: 1.5rem auto;

    .error-icon {
      padding-top: 44px;
      margin: auto;
    }
  }
}
.form-container {
  padding-top: 40px;
}
.pnr-msg {
  width: 80%;
  margin: auto;
}
