
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
.declarationText {
  font-size: 16px;
  color: $ads-dark-70;
  font-weight: 500;
  .navy {
    color: $ads-navy;
  }
  .confirm {
    font-size: 14px;
    margin-bottom: 30px;
    color: $ads-grey-02;
    margin-top: 0px;
  }
  .checkbox {
    color: $ads-grey-01;
    line-height: 23px;
  }
  h3 {
    font-size: 20px;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-top: 1px;
    margin-right: 15px;
  }
  ::v-deep .v-input__slot {
    align-items: flex-start;
  }
}

.declaration-label {
  line-height: 1.5;
  margin-top: 15px;
}
.parent-carer__email {
  margin-top: 2.5rem;
  ::v-deep label {
    top: 0;
    margin-left: -10px;
  }
}

::v-deep .AppTypeAhead {
  width: 100%;
}

::v-deep .AppTypeAhead_input {
  max-width: 420px;
  width: 100%;
  margin-right: 0;
  background: url('~@/assets/icon-magnify.svg') no-repeat scroll 13px 18px;
  padding: 0 2.5em;
}

.prefilledAlert {
  & ::v-deep .v-alert {
    max-width: 420px;
    box-shadow: none !important;
  }

  & ::v-deep .v-alert__icon {
    margin: auto 16px auto 0;
  }
}
.pnrSchoolLookup {
  max-width: 420px;
}
.invalidSchoolAlert {
  ::v-deep .v-alert {
    background-color: $color-orange-light !important;
    box-shadow: none !important;
    max-width: 420px;

    p {
      margin-bottom: 0 !important;
      font-weight: 400 !important;
      line-height: 19px;
    }
  }

  ::v-deep .theme--light.v-icon {
    color: $ads-warning-orange !important;
  }
}
