
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.signout {
  margin-bottom: 4rem;

  &__info {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
}

h2 {
  font-size: 1.25rem;
}

.info {
  &__icon-wrapper {
    margin-right: 2rem;

    @include mobile {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  &__icon--alert {
    margin-top: -0.5rem;
  }

  &__text {
    margin-bottom: 0;
    line-height: 1.7;

    &,
    a {
      color: $ads-dark;
    }
  }
}
