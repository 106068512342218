
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.error-container {
  background-color: $ads-white;
  border-radius: 4px;
  text-align: center;

  h1 {
    color: $ads-navy !important;
  }
  h2 {
    font-size: 24px;
    color: $ads-navy !important;
  }
  .icon-container {
    width: 161px;
    height: 161px;
    text-align: center;
    vertical-align: middle;
    margin: 1.5rem auto;

    .error-icon {
      padding-top: 30px;
      margin: auto;
    }
  }
}
.form-container {
  padding-top: 40px;
}
