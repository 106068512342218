
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.banner {
  margin-top: 1px;
  background-image: url(../assets/bg-banner@3x.png);
  background-position: right top;
  background-size: auto 218px;
  background-repeat: no-repeat;
  background-color: $ads-navy;
  color: $ads-white;

  @include tablet-wide {
    background-size: auto 100%;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__heading,
  &__sub-heading {
    word-break: break-word;
  }

  &__heading {
    font-size: 3.125rem;
    line-height: 1;
  }

  &__subheading {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  &__icon {
    margin-right: 1.5rem;
    width: 6rem;
  }

  &__sub-heading {
    font-weight: normal;
    font-size: 22px;
  }

  @include tablet {
    &__heading {
      font-size: 2.5rem;
    }

    &__sub-heading {
      font-size: 1.25rem;
    }

    &__icon {
      width: 4.8rem;
      margin-right: 1.25rem;
    }
  }

  @include mobile-lg {
    &__heading {
      font-size: 2rem;
    }

    &__sub-heading {
      font-size: 1rem;
    }

    &__icon {
      width: 3.75rem;
      margin-right: 1rem;
    }
  }

  @include mobile {
    &__heading {
      font-size: 1.25rem;
    }

    &__sub-heading {
      font-size: 0.75rem;
    }

    &__icon {
      width: 2.5rem;
      margin-right: 1rem;
    }
  }
}

.banner.compact {
  h1 {
    font-size: 2.5rem;
    line-height: 1.3;
  }
  @include mobile {
    h1 {
      font-size: 1.25rem;
    }
  }
}
