
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.adsButton {
  width: 90%;
}
.login-divider {
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem 0;
}
.width35 {
  font-size: 2.2rem;
}
.width40 {
  font-size: 2.5rem;
}
