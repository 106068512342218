
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

a:focus {
  border: 2px solid $ads-navy !important;
  outline-color: $ads-navy !important;
}

a {
  text-decoration: underline;
}

.email-login__heading,
.email-login__text {
  color: $color-text-body;
}

.email-login__button {
  width: 100%;
}

.button__spinner {
  margin-right: 0.25rem;
}

::v-deep .login-card__tnc.v-input--checkbox .v-label {
  display: inline-block;
  color: $color-text-body;
  font-weight: 500;
}
