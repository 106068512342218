
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.eoiClosedInfo {
  margin-top: 5rem;
  font-size: 24px;
  color: $ads-primary-blue;
}
