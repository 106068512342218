
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.plainText {
  font-weight: normal;
}
.applicationId {
  word-break: break-word;
}
