
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.content-box {
  margin: 0px 15px;
}
.mobile .content-img {
  margin-bottom: 12px;
}
.content-img img {
  width: 70px;
  height: 70px;
}
.content-img img.largeImage {
  width: 88px;
  height: 88px;
}
.content-img.rightSpacing {
  margin-right: 40px;
}
.largeImage .content-img.rightSpacing {
  margin-right: 25px;
}
