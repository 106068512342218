
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.EnvironmentInfo {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 500;

  font-size: 10px;
  line-height: 1.5;

  margin: 15px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(black, 0.1);
  background-color: white;
  color: $color-text-body;
}

.slide-right-leave-active {
  transition: all 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
.slide-right-leave-to {
  transform: translateX(200%);
}
