
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.login-divider {
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem 0;
}

.centeredContent {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

::v-deep p {
  margin-bottom: 0;
}
