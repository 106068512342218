
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.school-card {
  display: flex;
  align-items: center;
  padding: 3rem;

  @include mobile {
    flex-direction: column;
  }

  &__logo {
    height: 7rem;
    padding-right: 3rem;
    max-width: 100%;
    max-height: 100%;

    @include mobile {
      padding-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  &__heading,
  &__radio.v-radio ::v-deep label.v-label {
    color: $ads-navy;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5;
  }

  &__heading,
  &__radio {
    margin-bottom: 0.5rem;
  }

  &__list {
    list-style: none;
    padding: 0;

    &.indent {
      margin-left: 2.5rem;
    }
  }

  &__list-item {
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.4rem 0;
    display: flex;
    align-items: center;
  }
}

::v-deep label.v-label {
  margin-left: 0.5rem;
}

.list-item {
  &__text {
    padding-left: 1rem;
    font-weight: 500;
  }

  &__text,
  &__icon {
    color: $ads-navy;
  }

  &__icon {
    margin-left: -3px;
  }
}
