// doe-colour palette
@import '~@nswdoe/doe-ui-core/styles/variables.scss';

// Application backgound
$color-bg-app: $ads-light-10;
$color-text-body: #002040;
$color-bg-section: #fafafa;
$color-green-3: #228000;
$color-text-light: #666666;
$color-bg-expansionPanel: #e5e5e5;
$color-ads-primary-teal-2: #cbedfd66;
$color-primary: #041e42;
$color-white: #fff;
$color-placeholders: #727272;
$color-black: #000;
$color-third: #c8dcf0;
$color-orange-light: #FFCE9966;

// BREAKPOINTS
$breakpoint-desktop: 992px;
$breakpoint-tablet: 768px;
$breakpoint-mobile-lg: 667px;
$breakpoint-mobile: 480px;

$basic-transition: all 0.3s ease-in-out;
