
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-dialog > .v-card > .v-card__title.modal__banner {
  padding: 0.25rem;
  justify-content: flex-end;
}
.v-card.modal__content .modal__body {
  padding: 0 2rem 2rem 2rem;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  outline: none;

  &:first-child {
    padding: 2rem;
  }
}
.modal__close-button {
  top: 1rem;
  right: 1rem;
  border: none;
}
