
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#PrivacyAgreementMdl {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: $ads-primary-blue;
  }

  a {
    color: $ads-primary-blue;
  }

  h2 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: $ads-primary-blue;
    line-height: 1.5rem;
  }

  p,
  .contentPoint {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: $ads-grey-01;
  }

  .linkPoint {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: $ads-primary-blue;
  }

  li {
    margin-left: -0.5em;
    font-weight: 500;

    &::marker {
      font-size: 0.75em;
    }
  }
}
