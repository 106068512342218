
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.email-otp__button {
  width: 100%;
  height: 56px !important;
}
.button__spinner {
  margin-right: 0.5rem;
}
.button__icon {
  margin-right: 0.25rem;
}
.email-otp {
  color: $color-text-body;
}
