
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.SchoolNameLookup {
  .ApplicationField {
    .fieldText {
      width: 100% !important;
    }
  }

  .fieldContent {
    input {
      width: 100%;
    }
  }

  .alertRow {
    display: flex;
    padding-top: 0.5rem;

    svg {
      position: relative;
      top: -0.5rem;
      height: 3em;
      color: $ads-red;
    }

    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
      color: $ads-primary-red;
      font-size: 1rem;
    }
  }
  .label {
    @include vuetify-label;
    margin-left: 0.6rem;
    padding: 0 0.2rem;
  }

  .hasAlert {
    label {
      color: $ads-primary-red;
    }
    ::v-deep .AppTypeAhead_input {
      border: 2px solid $ads-primary-red;
      background: url('~@/assets/icon-magnify-red.svg') no-repeat scroll 13px
        18px;
    }
  }
}
