
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.footer {
  background-color: $ads-navy;
  height: 190px;
  display: flex;
  align-items: center;

  .footer-logo {
    flex-grow: 0;
    margin: 0;
    min-width: 64px;
    align-self: center;
    padding-left: 0;

    .logo {
      width: 7rem;
    }
  }

  .links {
    display: flex;
    justify-content: flex-end;
    color: $ads-white;
    font-size: 1rem;
    text-decoration: none;

    > :not(:last-child) {
      margin-right: 5rem;
    }

    &__title {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1.5;
      padding-bottom: 1rem;
    }

    &__list {
      margin-bottom: 0;

      > :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  .list-link {
    display: flex;
    align-items: center;

    &__icon {
      margin-right: 1rem;
      color: $ads-white;
    }

    &__additional-info {
      padding-left: 2.5rem;
      display: block;
    }
  }

  a {
    text-decoration: none;
    color: $ads-white;
    font-size: 1rem;
    font-weight: 500;
  }

  @include tablet {
    height: auto;
    .footer-logo {
      margin-right: 1.5rem;
    }
    .links > :not(:last-child) {
      margin-right: 2rem;
    }
  }

  @include mobile-lg {
    .row {
      display: block;
    }
    .links {
      display: block;
      &__privacy,
      &__support {
        margin-bottom: 1.25rem;
      }
    }
  }
}
