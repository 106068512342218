@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin tablet-wide {
  @media (max-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$breakpoint-tablet}) {
    @content;
  }
}

@mixin mobile-lg {
  @media (max-width: #{$breakpoint-mobile-lg}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-mobile}) {
    @content;
  }
}

@mixin vuetify-label {
  margin-bottom: -1.2rem;
  margin-top: -0.7rem;
  z-index: 2;
  margin-left: 1rem;
  padding: 0 0.3rem;
  font-size: 1rem;
  border-radius: 0;
  font-weight: bold;
  color: $color-primary;
  background-color: $color-white;
  position: absolute;
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
