
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-subheader.trainingOptions {
  font-size: 1rem;
  color: $ads-dark;
}
