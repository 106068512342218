
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

#LogoutConfirmationModal {
  font-weight: 500;
  margin: 0 -0.5rem -0.5rem -0.5rem;

  .heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.25rem;
  }

  .actions {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin-top: 2rem;

    & button {
      font-weight: bold;

      padding: 0 2.5rem;
      height: 3rem;

      @include mobile {
        display: block;
        width: 100%;

        &:not(:last-child) {
          margin-top: 0.5rem;
        }
      }
      ::v-deep .v-btn__content {
        font-size: 16px;
      }
    }
  }

  h1 {
    color: $ads-navy;
    font-weight: bold;
    font-size: 1.25rem;
  }

  p:last-child {
    margin: 0;
  }
}
