
          @import "@/scss/variables.scss";
          @import "@/scss/mixins.scss";
        
@import '@/scss/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep,
* {
  .slide-enter-active,
  .slide-leave-active,
  .slide-move {
    overflow-y: hidden;
    transition-property: all;
  }

  .slide-enter,
  .slide-leave-to {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    max-height: 0 !important;
  }
}
