// Override vuetify styles
.theme--light.v-application {
  background-color: $color-bg-app;
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;

  .error--text {
    color: $ads-error-red !important;
    caret-color: $ads-error-red !important;
  }
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: $ads-dark;
}

.theme--light.v-input input::placeholder,
.theme--light.v-input textarea::placeholder {
  color: $ads-dark-60;
}

// disabled field styles
.theme--light.v-input input[disabled='disabled'],
.theme--light.v-input textarea[disabled='disabled'] {
  color: $ads-dark-70;
  font-weight: 500;
}

.theme--light.v-text-field--outlined:not(
    .v-input--is-focused
  ).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 1px solid $ads-light-20;
  background-color: $ads-light-10;
}

// Readonly field styles
// TODO: See if there is a better way of overwriting the css style for readonly fields.
// DatePicker uses readonly text field, so added 'readonly-text' class to refine the selector so
// we only apply the style to genuine readonly input text fields.
.theme--light.v-input.readonly-text input[readonly='readonly'],
.theme--light.v-input.readonly-text textarea[readonly='readonly'] {
  color: $ads-dark-70;
  font-weight: 500;
}

.theme--light.v-text-field--outlined.v-input--is-readonly.readonly-text
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 1px solid $ads-light-20;
  background-color: $ads-light-10;
}

.v-text-field .v-label {
  padding-right: 2px;
}

.theme--light.v-text-field .v-label,
.theme--light.v-input--radio-group legend {
  color: $ads-navy;
  font-weight: bold;
  font-size: 1rem;
}

.theme--light.v-text-field .v-label {
  font-size: calc(1rem + 1rem * 0.25);
}

.ooa-form {
  .v-text-field.v-input:not(.v-textarea) {
    max-width: 420px;
    margin-bottom: 10px;
  }
}

.theme--light.v-icon {
  color: $ads-primary-blue;
}

.v-btn {
  transition: all 500ms;
  text-transform: none;
  letter-spacing: normal;
  transition: all 500ms;
}

// outlined button style
.theme--light.v-btn--outlined {
  border-width: 2px;
}

.v-form {
  border-radius: 4px;
}

p {
  font-weight: 500;
}

h2 {
  font-size: 1.375em;
}

a {
  color: $ads-navy;
  font-weight: 700;
}

.content {
  padding: 1px 0 4rem;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $color-text-light !important;
  color: $ads-white !important;
  border: 0;
}

.centeredContent {
  max-width: 924px;

  @include mobile {
    width: 100%;
  }
}

.contentPadding {
  padding: 0 50px;

  @include tablet {
    padding: 0 20px;
  }

  @include mobile {
    padding: 0 10px;
  }
}

.white-card {
  background: $ads-white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

//Generic Styles for the app.
.underline {
  text-decoration: underline;
}

.v-input
  .v-text-field__slot
  label.v-label.v-label--active.theme--light.error--text,
.v-input.v-input--is-focused
  .v-text-field__slot
  label.v-label.v-label--active.theme--light.error--text {
  color: $ads-error-red !important;
}

.v-input.error--text.theme--light .v-messages.error--text .v-messages__message {
  color: $ads-error-red !important;
  font-size: 16px;
  line-height: 16px;
}

.v-input--radio-group--column .v-input--radio-group__input > .v-label {
  height: auto;
}
.v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding: 5px 12px 5px 0;
}
.mobile .v-autocomplete__content .v-list-item__title,
.mobile .v-autocomplete__content .v-list-item__subtitle {
  text-overflow: initial;
  white-space: initial;
}

.school-finder__search .v-autocomplete__content .v-list-item__title {
  text-overflow: clip;
  white-space: normal;
}

button.v-btn.btn-link.v-btn--text {
  color: $ads-navy;
  height: auto;
  padding: 0;
  font-size: inherit;
  min-width: 0;
  text-decoration: underline;
  font-weight: bold;
  outline: none;
}

.grey-box {
  background-color: $ads-light-10;
  padding: 0.75rem;
  border-radius: 4px;
}

.form-card {
  @extend .white-card;
  padding: 2rem 0;

  & > :not(hr) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .v-divider {
    margin-top: 1rem;
    margin-bottom: 1.75rem;
  }
}

// Transition definitions
.fade-in-enter-active {
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.fade-in-leave-active {
  transition: opacity 225ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fade-in-enter,
.fade-in-leave-to {
  opacity: 0;
}

// Override vuetify styling for disabled button
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: inherit !important;
}

.v-label {
  word-break: break-word;
}

// screen reader text style
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Hint-text change
.theme--light.v-messages {
  color: $ads-dark-70;
}

.theme--light.v-input--checkbox,
.theme--light.v-radio {
  .v-label {
    color: $ads-dark;
  }
}
// v-checkbox a11y focus state updates
.theme--light.v-input--selection-controls.v-input--checkbox.v-input--is-focused
  .v-input--selection-controls__ripple {
  border: 2px solid $ads-navy;
}
// v-expansion-panel a11y focus state updates
.v-expansion-panel-header:not(.v-expansion-panel-header--mousedown):focus {
  .v-expansion-panel-header__icon {
    border: 2px solid $ads-navy;
    border-radius: 50%;
  }
}
// settingsBtn focus
.theme--dark.v-btn.settingsBtn.v-size--default:focus {
  border: 3px solid $ads-blue-2 !important;
}

// modal close button focus
.theme--light.v-btn.v-btn--round:focus {
  border: 3px solid $ads-navy;
}

// //date picker focus indicator for not selected day.
.theme--light.v-date-picker-table--date.v-date-picker-table .theme--light.v-btn--rounded.v-btn--text.v-btn:focus,
// //date picker focus indicator for not selected month.
.theme--light.v-date-picker-table--month.v-date-picker-table .theme--light.v-btn--text.v-size--default.v-btn:focus {
  border: 3px solid $ads-navy !important;
  color: $ads-navy;
  font-weight: 700;
}

//date picker focus indicator for day already selected button .
.theme--light.v-date-picker-table--date.v-date-picker-table .accent.theme--light.v-btn--rounded.v-btn--active.v-btn:focus,
//date picker focus indicator for month already selected button .
.theme--light.v-date-picker-table--month.v-date-picker-table .accent.theme--light.v-btn--active.v-size--default.v-btn:focus {
  border: 3px solid $ads-blue-2 !important;
  background-color: $ads-navy !important;
}

//date picker focus indicator for day already selected button .
.theme--light.v-date-picker-table--date.v-date-picker-table .accent.theme--light.v-btn--rounded.v-btn--active.v-btn:before,
//date picker focus indicator for month already selected button .
.theme--light.v-date-picker-table--month.v-date-picker-table .accent.theme--light.v-btn--active.v-size--default.v-btn:before {
  opacity: 0;
}

//to override date picker day already selected button back ground color.
.theme--light.v-date-picker-table--date.v-date-picker-table .accent.theme--light.v-btn--rounded.v-btn--active.v-btn,
//to override date picker month already selected button back ground color.
.theme--light.v-date-picker-table--month.v-date-picker-table .accent.theme--light.v-btn--active.v-size--default.v-btn {
  background-color: $ads-navy !important;
}

.theme--light.v-btn.v-btn--round:focus::before {
  opacity: 0;
}
.v-autocomplete .v-icon.v-icon--link:focus {
  border: 3px solid $ads-dark-60;
  border-radius: 50%;
}
.v-autocomplete .v-icon.v-icon--link:focus::after {
  opacity: 0;
}

.mobile
  .v-toolbar__content
  .v-app-bar__nav-icon.v-btn.v-btn--icon.v-size--default {
  width: auto;
}

.v-list-item:focus:not(a),
.v-list-item.v-list-item--highlighted {
  border: 4px solid $ads-info-blue !important;
}

.theme--light.v-menu__content.v-autocomplete__content
  .v-list
  .v-list-item--link.v-list-item--highlighted {
  background-color: transparent !important;
}

.theme--light.v-list-item.v-list-item--active,
.theme--light.v-list-item.v-list-item--active,
.theme--light.v-menu__content.v-autocomplete__content
  .v-list
  .v-list-item--link.v-list-item--active {
  background-color: $ads-navy !important;
  color: $ads-white !important;
}
.theme--light.v-list-item.v-list-item--highlighted::before {
  opacity: 0 !important;
}

.text--black {
  color: $ads-dark;
}
